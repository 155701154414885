// init and destroy slider on resize
import responsiveSlider from "./_responsiveSlider.js"

jQuery(document).ready(function($) {
    $('#benefits ul').ResponsiveSlider({
        'colTarget': '> li',
        init: function($slider) {
            $slider.slick({
                infinite: true,
                autoplay: true,
                autoplaySpeed: 3000,
                arrows: false,
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            }); // or this.$slider.owlCarousel( thisconfig..opts );
        }
    });

    $('#reviews .k2ItemsBlock > ul').ResponsiveSlider({
         maxWidth: 992,
        'colTarget': '> li',
        init: function($slider) {
            $slider.slick({
                infinite: false,
                autoplay: false,
                autoplaySpeed: 4000,
                arrows: true,
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                adaptiveHeight: true,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            }); // or this.$slider.owlCarousel( thisconfig..opts );
        }
    });

    $('#scheme_w > ul').ResponsiveSlider({
        // maxWidth: 992,
        'colTarget': '> li',
        init: function($slider) {
            $slider.slick({
                infinite: true,
                autoplay: false,
                autoplaySpeed: 4000,
                arrows: true,
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            }); // or this.$slider.owlCarousel( thisconfig..opts );
        }
    });
});

    var navigation = document.getElementById('nav');
    var navServices = document.getElementById("nav-services");
    var navMenu = navigation.getElementsByClassName('moduletable_menu')[0];

    var nodeButton = document.createElement('button');
    nodeButton.innerHTML = '<span class="hamburger-box"><span class="hamburger-inner"></span></span>';
    nodeButton.innerHTML+= '<span class="button-label">Меню</span>'
    nodeButton.className = 'navbar-toggler hamburger hamburger--elastic';
    nodeButton.setAttribute('type', 'button');
    nodeButton.setAttribute('data-toggle', 'collapse');
    nodeButton.setAttribute('data-target', '#default-collapse');
    nodeButton.setAttribute('aria-expanded', 'false');

    navigation.prepend( nodeButton );

    nodeButton.addEventListener("click", function() {
        this.classList.toggle('is-active');
        navMenu.classList.toggle("expanded");
    });

    var headings = document.querySelectorAll('.moduletable_oz h3, .moduletable h3');
    for (var i = headings.length - 1; i >= 0; i--) {
        headings[i].addEventListener("click", function() {
            this.classList.toggle('is-active');
            this.parentNode.getElementsByTagName('ul')[0].classList.toggle("expanded");
        });
    }

    if( ! 'ontouchstart' in document.documentElement ) {
        navigation.getElementsByClassName("item-114")[0].addEventListener("mouseover", function(){
            navServices.classList.toggle("nav-services-hidden");
        });

        navServices.addEventListener("mouseleave", function(){
            navServices.classList.toggle("nav-services-hidden");
        });
    }

jQuery(document).ready(function($) {
    $('.panel').tabSlideOut({                            //Класс панели
        tabHandle: '.handle',                       //Класс кнопки
        pathToTabImage: 'https://doktormazitov.ru/templates/mazitov/images/show.png',               //Путь к изображению кнопки
        imageHeight: '30px',                        //Высота кнопки
        imageWidth: '144px',                        //Ширина кнопки
        tabLocation: 'bottom',                      //Расположение панели top - выдвигается сверху, right - выдвигается справа, bottom - выдвигается снизу, left - выдвигается слева
        speed: 300,                             //Скорость анимации
        action: 'click',                                //Метод показа click - выдвигается по клику на кнопку, hover - выдвигается при наведении курсора
        topPos: '200px',                            //Отступ сверху
        fixedPosition: true             //Позиционирование блока false - position: absolute, true - position: fixed
    });
    $(".call").click(function(){
        $(".call_me").slideDown();
        $(".over").fadeIn();
    });
    $(".over").click(function(){
        $(".call_me").slideUp();
        $(".over").fadeOut();
    });
    $(".close").click(function(){
        $(".call_me").slideUp();
        $(".over").fadeOut();
    });
    $("#reviews .more_r").click(function(){
        $("#reviews ul li").slideDown();
        $("#reviews .more_r").fadeOut();
        $("#reviews .more1_r").fadeIn();
    });
    $("#reviews .more1_r").click(function(){
        $("#reviews ul li").slideUp();
        $("#reviews ul li:nth-child(1)").fadeIn();
        $("#reviews ul li:nth-child(2)").fadeIn();
        $("#reviews ul li:nth-child(3)").fadeIn();
        $("#reviews .more1_r").fadeOut();
        $("#reviews .more_r").fadeIn();
    });

    if( typeof $.jTruncate == 'function' ) {
        $('#about .left').jTruncate({
            length: 300,
            minTrail: 0,
            moreText: "...",
            lessText: "Скрыть",
            ellipsisText: "",
            moreAni: "fast",
            lessAni: 2000
        });
    }
});